import React from 'react';

import { styled } from '../../stitches.config';
import { radii } from '../../themes/eneco/tokens';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps, createVariants } from '../../util/css/stitches';

export const StyledBox = styled('div', {
  variants: {
    /** `paddingX` creates space at the left and right of an element */
    paddingX: {
      0: { paddingX: 0 },
      1: { paddingX: '$1' },
      2: { paddingX: '$2' },
      3: { paddingX: '$3' },
      4: { paddingX: '$4' },
      5: { paddingX: '$5' },
      6: { paddingX: '$6' },
      7: { paddingX: '$7' },
      8: { paddingX: '$8' },
      10: { paddingX: '$10' },
      12: { paddingX: '$12' },
      16: { paddingX: '$16' },
      24: { paddingX: '$24' },
      32: { paddingX: '$32' },
    },
    /** `paddingY` creates space at the top and bottom of an element */
    paddingY: {
      0: { paddingY: 0 },
      1: { paddingY: '$1' },
      2: { paddingY: '$2' },
      3: { paddingY: '$3' },
      4: { paddingY: '$4' },
      5: { paddingY: '$5' },
      6: { paddingY: '$6' },
      7: { paddingY: '$7' },
      8: { paddingY: '$8' },
      10: { paddingY: '$10' },
      12: { paddingY: '$12' },
      16: { paddingY: '$16' },
      24: { paddingY: '$24' },
      32: { paddingY: '$32' },
    },
    padding: {
      0: { padding: 0 },
      1: { padding: '$1' },
      2: { padding: '$2' },
      3: { padding: '$3' },
      4: { padding: '$4' },
      5: { padding: '$5' },
      6: { padding: '$6' },
      7: { padding: '$7' },
      8: { padding: '$8' },
      10: { padding: '$10' },
      12: { padding: '$12' },
      16: { padding: '$16' },
      24: { padding: '$24' },
      32: { padding: '$32' },
    },
    paddingLeft: {
      0: { paddingLeft: 0 },
      1: { paddingLeft: '$1' },
      2: { paddingLeft: '$2' },
      3: { paddingLeft: '$3' },
      4: { paddingLeft: '$4' },
      5: { paddingLeft: '$5' },
      6: { paddingLeft: '$6' },
      7: { paddingLeft: '$7' },
      8: { paddingLeft: '$8' },
      10: { paddingLeft: '$10' },
      12: { paddingLeft: '$12' },
      16: { paddingLeft: '$16' },
      24: { paddingLeft: '$24' },
      32: { paddingLeft: '$32' },
    },
    paddingRight: {
      0: { paddingRight: 0 },
      1: { paddingRight: '$1' },
      2: { paddingRight: '$2' },
      3: { paddingRight: '$3' },
      4: { paddingRight: '$4' },
      5: { paddingRight: '$5' },
      6: { paddingRight: '$6' },
      7: { paddingRight: '$7' },
      8: { paddingRight: '$8' },
      10: { paddingRight: '$10' },
      12: { paddingRight: '$12' },
      16: { paddingRight: '$16' },
      24: { paddingRight: '$24' },
      32: { paddingRight: '$32' },
    },
    paddingTop: {
      0: { paddingTop: 0 },
      1: { paddingTop: '$1' },
      2: { paddingTop: '$2' },
      3: { paddingTop: '$3' },
      4: { paddingTop: '$4' },
      5: { paddingTop: '$5' },
      6: { paddingTop: '$6' },
      7: { paddingTop: '$7' },
      8: { paddingTop: '$8' },
      10: { paddingTop: '$10' },
      12: { paddingTop: '$12' },
      16: { paddingTop: '$16' },
      24: { paddingTop: '$24' },
      32: { paddingTop: '$32' },
    },
    paddingBottom: {
      0: { paddingBottom: 0 },
      1: { paddingBottom: '$1' },
      2: { paddingBottom: '$2' },
      3: { paddingBottom: '$3' },
      4: { paddingBottom: '$4' },
      5: { paddingBottom: '$5' },
      6: { paddingBottom: '$6' },
      7: { paddingBottom: '$7' },
      8: { paddingBottom: '$8' },
      10: { paddingBottom: '$10' },
      12: { paddingBottom: '$12' },
      16: { paddingBottom: '$16' },
      24: { paddingBottom: '$24' },
      32: { paddingBottom: '$32' },
    },
    /** `backgroundColor` defines the color of the background */
    backgroundColor: {
      backgroundPrimary: {
        background: '$backgroundPrimary',
      },
      backgroundSecondary: {
        background: '$backgroundSecondary',
      },
      backgroundTertiary: {
        background: '$backgroundTertiary',
      },
      backgroundBrand: {
        background: '$backgroundBrand',
      },
      backgroundDark: {
        background: '$backgroundDark',
      },
      formErrorMessageBackground: {
        background: '$formErrorMessageBackground',
      },
      backgroundVarOne: {
        background: '$backgroundVarOne',
      },
      backgroundVarTwo: {
        background: '$backgroundVarTwo',
      },
      backgroundVarThree: {
        background: '$backgroundVarThree',
      },
      backgroundVarFour: {
        background: '$backgroundVarFour',
      },
      backgroundVarFive: {
        background: '$backgroundVarFive',
      },
      backgroundVarSix: {
        background: '$backgroundVarSix',
      },
      feedbackBackgroundError: {
        background: '$feedbackBackgroundError',
      },
      feedbackBackgroundSuccess: {
        background: '$feedbackBackgroundSuccess',
      },
      feedbackBackgroundWarning: {
        background: '$feedbackBackgroundWarning',
      },
      feedbackBackgroundInfo: {
        background: '$feedbackBackgroundInfo',
      },
      backgroundCMSVarOne: {
        background: '$backgroundCMSVarOne',
      },
      backgroundCMSVarTwo: {
        background: '$backgroundCMSVarTwo',
      },
      backgroundCMSVarThree: {
        background: '$backgroundCMSVarThree',
      },
      backgroundCMSVarFour: {
        background: '$backgroundCMSVarFour',
      },
      backgroundCMSVarFive: {
        background: '$backgroundCMSVarFive',
      },
      backgroundCMSVarSix: {
        background: '$backgroundCMSVarSix',
      },
      backgroundCMSVarSeven: {
        background: '$backgroundCMSVarSeven',
      },
      backgroundCMSVarEight: {
        background: '$backgroundCMSVarEight',
      },
    },
    borderRadius: createVariants(radii, 'borderRadius'),
    borderTopLeftRadius: createVariants(radii, 'borderTopLeftRadius'),
    borderTopRightRadius: createVariants(radii, 'borderTopRightRadius'),
    borderBottomLeftRadius: createVariants(radii, 'borderBottomLeftRadius'),
    borderBottomRightRadius: createVariants(radii, 'borderBottomRightRadius'),
    overflow: {
      hidden: {
        overflow: 'hidden',
      },
      auto: {
        overflow: 'auto',
      },
    },
  },
});

const stitchesClassName = 'sparky-box';

export const Box = React.forwardRef<HTMLDivElement, BoxProps>(
  ({ children, as = 'div', className = '', id, ...props }, ref) => {
    const variantProps = extractVariantProps(props);

    return (
      <StyledBox as={as} ref={ref} {...variantProps} className={`${stitchesClassName} ${className}`} id={id}>
        {children}
      </StyledBox>
    );
  },
);

Box.toString = () => `.${stitchesClassName}`;
StyledBox.displayName = 'styled(Box)';
Box.displayName = 'Box';

type BoxVariants = TransformStitchesToSparky<typeof StyledBox>;
interface BoxProps extends BoxVariants {
  /** The DOM element to render*/
  as?: keyof JSX.IntrinsicElements;
  children: React.ReactNode;
  className?: never;
  id?: string;
}
